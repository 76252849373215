import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup, Nav } from 'react-bootstrap';
import { FaFileAlt, FaRegFrownOpen } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import CustomModal from '../../../components/CustomModal';
import NothingToShow from '../../../components/NothingToShow';
import PageContainer from '../../../components/PageContainer';
import { menuRelatorios, applyFiltroMenu } from './RelatoriosMainPageInfo';

import './RelatoriosMainPage.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import Loading from '../../../components/Loading';
import useAlertNotification from '../../../context/AlertNotificationContext';
import FiltroHistoricoCusto from './CustomModalRelatorios/FiltroHistoricoCusto';
import FiltroRelatorioCapacidade from './CustomModalRelatorios/FiltroRelatorioCapacidade';
import FiltroRelatorioComparativo from './CustomModalRelatorios/FiltroRelatorioComparativo';
import CadastroClientesService from '../../../services/api/CadastroClientesService'
import { RelatorioListHelpModal } from './RelatorioListHelpModal';
import { FiInfo } from "react-icons/fi";
import FiltroCapacidadePorProjeto from './CustomModalRelatorios/FiltroCapacidadePorProjeto';

function RelatoriosMainPage(props) {

    //Uses
    const history = useHistory();
    const alert = useAlertNotification();

    //States
    const [filtro, setFiltro] = useState("");
    const [filtrado, setFiltrado] = useState(menuRelatorios);
    const [dataProjects, setDataProjects] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [activePopup, setActivePopup] = useState(null);

    //Chamadas de requisição
    const getProjectsData = async () => {
        try {
            const data = await ProjetosApiService.getAllProject();
            setDataProjects(data.data.data);
        } catch (error) {
            console.error('Erro ao obter os projetos:', error);
        }
    }

    const getClientsList = async () => {
        try{
            const data = await CadastroClientesService.getAllClients();
            setClientes(data.data)
        } catch (error) {
            console.error('Erro ao chamar todos os clientes:', error);
        }
    }

    //Funções de passagem de dados para as páginas dos relatórios
    const clearFiltro = () => setFiltro("");

    const handleFiltro = () => {
        const menuFiltrado = applyFiltroMenu(filtro);
        setFiltrado(menuFiltrado);
    }

    const handleDadosProjetos = (projetoSelecionado, anoSelecionado, pathUrl) => { 
        if(projetoSelecionado != "" && anoSelecionado != ""){
            history.push(`${pathUrl}/${projetoSelecionado}-${anoSelecionado}`);
        }
        else{
            alert.error("Preencha todos os campos antes de prosseguir.")
        }
    };  

    const handleDadosProjetosHistorico = (projetosSelecionados, radioValue, periodoInicial, periodoFinal, tipoCusto, clienteSelecionado) => {

        if (projetosSelecionados.length == 0 || !radioValue || !periodoInicial || !periodoFinal || !tipoCusto ) {
            alert.error("Preencha todos os campos* antes de prosseguir.");
        } else if(periodoFinal < periodoInicial) {
                alert.error("Período Inicial menor que Período Final.");
            } else {
                const dataInicialFormatada = new Date(periodoInicial.getFullYear(), periodoInicial.getMonth(), "01").toLocaleDateString('pt-BR');
        
                const ultimoDia = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth() + 1, 0).getDate();
                const dataFinalFormatada = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth(), ultimoDia).toLocaleDateString('pt-BR'); 
                const dados = {
                    "projetosSelecionados": projetosSelecionados,
                    "radioValue": radioValue,
                    "periodoInicial" : dataInicialFormatada,
                    "periodoFinal" : dataFinalFormatada,
                    "tipoCusto": tipoCusto,
                    "cliente" : clienteSelecionado 
                }
                history.push({
                    pathname: 'historico-custos',
                    state: { data: dados }
                });
            }
    }

    const handleDadosRelatorioCapacidade = (periodoInicial, periodoFinal) => {
        if(periodoFinal < periodoInicial) {
            alert.error("Período Inicial menor que Período Final.");
        } else {
            const dataInicialFormatada = new Date(periodoInicial.getFullYear(), periodoInicial.getMonth(), "01").toLocaleDateString('pt-BR');
    
            const ultimoDia = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth() + 1, 0).getDate();
            const dataFinalFormatada = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth(), ultimoDia).toLocaleDateString('pt-BR');            

            const dados = {
                "periodoInicial" : dataInicialFormatada,
                "periodoFinal" : dataFinalFormatada,                            
            }
            history.push({
                pathname: 'relatorio-capacidade',
                state: { data: dados }
            });
        }
    }

    const handleDadosProjetosCapacidade = (projetosSelecionados, radioValue, periodoInicial, periodoFinal, clienteSelecionado) => {

        if (projetosSelecionados.length == 0 || !radioValue || !periodoInicial || !periodoFinal ) {
            alert.error("Preencha todos os campos* antes de prosseguir.");
        } else if(periodoFinal < periodoInicial) {
                alert.error("Período Inicial menor que Período Final.");
            } else {
                const dataInicialFormatada = new Date(periodoInicial.getFullYear(), periodoInicial.getMonth(), "01").toLocaleDateString('pt-BR');
        
                const ultimoDia = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth() + 1, 0).getDate();
                const dataFinalFormatada = new Date(periodoFinal.getFullYear(), periodoFinal.getMonth(), ultimoDia).toLocaleDateString('pt-BR'); 
                const dados = {
                    "projetosSelecionados": projetosSelecionados,
                    "radioValue": radioValue,
                    "periodoInicial" : dataInicialFormatada,
                    "periodoFinal" : dataFinalFormatada,
                    "cliente" : clienteSelecionado 
                }
                history.push({
                    pathname: 'relatorio-capacidade-projeto',
                    state: { data: dados }
                });
            }
    }

    //Chamada dos modais dos relatórios
    const callFiltroEmissaoRelatorio = ({ path }) => {
        if(path.includes("realPlanejadoCusto")) {
            CustomModal.show({
                title: "Gerar Relatório",
                body: <FiltroRelatorioComparativo clientes={clientes} data={dataProjects} pathUrl="comparativo" handleDadosProjetos={handleDadosProjetos}/>
            })
        }
        if(path.includes("historicoCustoClienteProjeto")){
            CustomModal.show({
                title: "Histórico custo de cliente / projeto",
                body: <FiltroHistoricoCusto clientes={clientes} projetos={dataProjects} handleDadosProjetosHistorico={handleDadosProjetosHistorico}/>
            })
        }
        if(path.includes("relatorioCapacidade")){
            CustomModal.show({
                title: "Relatório Capacidade",
                body: <FiltroRelatorioCapacidade handleDadosRelatorioCapacidade={handleDadosRelatorioCapacidade}/>
            })
        }
        if(path.includes("realPlanejadoFaturamento")){
            CustomModal.show({
                title: "Gerar Relatório",
                body: <FiltroRelatorioComparativo clientes={clientes} data={dataProjects} pathUrl="comparativo-faturamento" handleDadosProjetos={handleDadosProjetos}/>
            })
        }
        if(path.includes("resultadoAnualProjeto")){
            CustomModal.show({
                title: "Gerar Relatório",
                body: <FiltroRelatorioComparativo clientes={clientes} data={dataProjects.filter((proj) => !proj.name.includes("INT-1000"))} pathUrl="resultado-anual" handleDadosProjetos={handleDadosProjetos}/>
            })
        }
        if(path.includes("capacidadeProjeto")){
            CustomModal.show({
                title: "Gerar Relatório",
                body: <FiltroCapacidadePorProjeto clientes={clientes} projetos={dataProjects.filter((proj) => !proj.name.includes("INT-1000"))} handleDadosProjetos={handleDadosProjetosCapacidade}/>
            })
        }
    }

    //Effects
    useEffect(handleFiltro, [filtro]);
    
    useEffect(()=> {
        getProjectsData()
        getClientsList()
    }, []);

    const handleMouseEnter = (iKey) => {
        setActivePopup(iKey);
    };

    const handleMouseLeave = () => {
        setActivePopup(null);
    };

    return (
        <PageContainer title="Relatórios Delivery" infoButton={< RelatorioListHelpModal />}>
            {dataProjects.length == 0 ? < Loading message={"Carregando..."}/> :  <>
            <header className="relatorios-main">
                <InputGroup>
                    <FormControl
                        className="filter-input"
                        value={filtro}
                        onChange={e => setFiltro(e.target.value)}
                        placeholder="Buscar relatório"
                        aria-label="Buscar relatório"
                        aria-describedby="busca-relatorio"
                        type="text"
                        autoComplete="off"
                    />
                    {filtro.length > 0 && (
                        <InputGroup.Text
                            id="busca-relatorio"
                            onClick={clearFiltro}
                            title="Limpar busca"
                        >
                            <MdClose/>
                        </InputGroup.Text>
                    )}
                </InputGroup>
            </header>

            { filtrado.length == 0 && (
                <div className="nenhum-relatorio">
                    <NothingToShow message={<>
                        <FaRegFrownOpen size={30}/> {`Nenhum relatório com o termo "${filtro}"`}
                    </>}/>
                </div>
            )}

            {filtrado.map((group, i) => {
                const { groupLabel, groupKey, menus } = group;
                const gKey = `${i}-${groupKey}`;

                return (
                <section key={gKey} className="relatorios-main">
                    <h4>{groupLabel}</h4>
                    <hr />
                    <Nav className="nav-container">
                    {menus.map((item, j) => {
                        const { label, path, info } = item;
                        const iKey = `${gKey}-${j}-${path.replace(" ", "")}`;

                        return (
                        <Nav.Item
                            className="col-md-16 col-lg-6 hover-info"
                            key={iKey}
                            onClick={() => callFiltroEmissaoRelatorio({ label, path })}
                            
                        >
                            <FaFileAlt size={20} />
                            <Nav.Link>
                                {label}
                                <span
                                    onMouseEnter={() => handleMouseEnter(iKey)}
                                    onMouseLeave={handleMouseLeave}
                                    className='ml-1 text-secondary'
                                >
                                    <FiInfo />
                                </span>
                            </Nav.Link>

                            {activePopup === iKey && (
                            <div className="hover-popup">
                                {`${info}`}
                            </div>
                            )}
                        </Nav.Item>
                        );
                    })}
                    </Nav>
                </section>
                );
            })}

            <CustomModal />
            </> }
        </PageContainer>
    )
}

export default RelatoriosMainPage;

