import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../../components/PageContainer";
import { useLocation } from 'react-router-dom';
import { MdOutlineArrowBack } from "react-icons/md";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlanejamentoApiService from "../../../services/api/PlanejamentoApiService";
import ProjetosApiService from "../../../services/api/ProjetosApiService";
import Loading from "../../../components/Loading";
import { Table, Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import ColaboradorApiService from "../../../services/api/ColaboradorApiService";
import ConvertDataService from "../../../services/util/ConvertDatas";
import UtilService from "../../../services/util";
import FuncaoApiService from "../../../services/api/FuncaoApiService";
import { FiInfo } from "react-icons/fi";

const popupStyle = {
    position: 'absolute',
    top: '-250%',
    left: '-600%',
    transform: 'translateX(-80%)',
    backgroundColor: '#e9e9e9',
    color: '#212529',
    border: '1px solid #dee2e6',
    borderRadius: '5px',
    padding: '5px 10px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: 'auto',
    fontWeight: 'normal'
};

function RelatorioCapacidadeProjetoView() {
    const [loading, setLoading] = useState(false);
    const [colaboradoresList, setColaboradoresList] = useState([]);
    const [colaboradoresComHoras, setColaboradoresComHoras] = useState([]);
    const [ordenacaoColaborador, setOrdenacaoColaborador] = useState([]);
    const [filteredColaboradores, setFilteredColaboradores] = useState([]);
    const [funcoes, setFuncoes] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedRegime, setSelectedRegime] = useState('');
    const [fetchDadosColaboradores, setFetchDadosColaboradores] = useState([]);
    const location = useLocation();
    const { data } = location.state || {};
    const history = useHistory();
    const [visiblePopup, setVisiblePopup] = useState(null);

    const dataInicialFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoInicial);
    const dataFinalFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoFinal);

    const fetchColaboradores = useCallback(async () => {
        setLoading(true)
        const KeysDoProjeto = data.projetosSelecionados.map(item => item.value);
        const colabByProject = (await ProjetosApiService.getDetailProject(KeysDoProjeto, data.periodoInicial, data.periodoFinal)).data.timesheet[0];
        try {
            const resp = (await ColaboradorApiService.getAll()).data.data;

            const colabFilter = colabByProject.map(filter => { return filter.accountId; });
            const colabFiltered = resp.filter(colab => colabFilter.includes(colab.accountId));

            const colaboradores = colabFiltered.filter(colab => 
                (colab.departamento === 5 || colab.departamento === 16 || (colab.departamento == 20 && colab.regime == 3)) && 
                (colab.regime !== 2)
            ); 
            const colaboradoresAtivos = colaboradores.filter(colaborador => 
                (!colaborador.demissao || colaborador.demissao >= dataInicialFiltro) &&
                colaborador.admissao <= dataFinalFiltro &&
                colaborador.accountId
            );
            setColaboradoresList(colaboradoresAtivos);
            setLoading(false)
        } catch (error) {
            console.error("Erro ao carregar colaboradores.", error);
        }
    }, [dataInicialFiltro, dataFinalFiltro]);

    const fetchFuncoes = useCallback(async () => {
        try {
            const resp = await FuncaoApiService.getAll();
            setFuncoes(resp.data.data);
        } catch (error) {
            console.error("Erro ao buscar as funções.", error);
        }
    }, []);
    
    async function fetchWithLimit(collaborators, limit) {
        const queue = [];
        for (let i = 0; i < collaborators.length; i += limit) {
            const chunk = collaborators.slice(i, i + limit);
            const promises = chunk.map(collab => fetchDataForCollaborator(collab));
            queue.push(...(await Promise.all(promises)));
        }
        setFetchDadosColaboradores(queue);
        setLoading(false)
        return queue;
    }
      
    async function fetchDataForCollaborator(colaborador) {
        setLoading(true)
        let parametros = [
            colaborador.accountId,
            dataInicialFiltro,
            dataFinalFiltro
        ];
    
        let planejadas, obrigatorias, realizadas, cargaHoraria;
    
        try {
            planejadas = await PlanejamentoApiService.getPlansByUserAndDate(parametros)
                .then(res => res.data.results);
        } catch (error) {
            console.error(`Erro ao buscar planos para ${colaborador.nome}:`, error);
            planejadas = [];
        }
    
        try {
            obrigatorias = await PlanejamentoApiService.getUserScheduleByUserAndDate([
                colaborador.accountId,
                new Date(colaborador.admissao) > new Date(dataInicialFiltro) ? colaborador.admissao : dataInicialFiltro,
                colaborador.demissao ? colaborador.demissao : dataFinalFiltro
            ]).then(res => res.data.results);
        } catch (error) {
            console.error(`Erro ao buscar horas obrigatórias para ${colaborador.nome}:`, error);
            obrigatorias = [];
        }
    
        try {
            const idsDoProjeto = data.projetosSelecionados.map(item => item.id);
            parametros = [...parametros, idsDoProjeto]
            realizadas = await PlanejamentoApiService.getProjectWorklogByUserAndDate(parametros)
                .then(res => res.data);
        } catch (error) {
            console.error(`Erro ao buscar worklogs para ${colaborador.nome}:`, error);
            realizadas = [];
        }

        try{
            cargaHoraria = await PlanejamentoApiService.getUserWorkload(colaborador.accountId)
            .then(res => res.data)
        }   catch(error){
            console.error(`Erro ao buscar carga horária para ${colaborador.nome}: `, error)
            cargaHoraria = [];
        }

        return { colaborador, planejadas, obrigatorias, realizadas, cargaHoraria };
    }

    useEffect(() => {
        fetchColaboradores();
        fetchFuncoes();
    }, [fetchColaboradores, fetchFuncoes]);

    useEffect(() => {
        if (colaboradoresList.length > 0) {
            fetchWithLimit(colaboradoresList, 5)
        }
    }, [colaboradoresList]);

    useEffect(() => {
        if (Array.isArray(fetchDadosColaboradores) && fetchDadosColaboradores.length > 0) {
    
            const resultadosFetch = fetchDadosColaboradores.map((item) => {
                const { colaborador, planejadas, obrigatorias, realizadas, cargaHoraria } = item;

                const idsDoProjeto = data.projetosSelecionados.map(item => item.id);
                
                const nomesDosProjetos = data.projetosSelecionados.map(item => item.name)
                
                const planejamentoFiltradoProjeto = planejadas.filter(item => idsDoProjeto.includes(item.planItem.id));

                let horasPlanejadas = planejamentoFiltradoProjeto.reduce((acc, plan) => acc + (plan.totalPlannedSecondsInScope || 0), 0) / 3600;

                let cargaHorariaTotal = (cargaHoraria.days[0].requiredSeconds);

                let horasPorDia = cargaHorariaTotal == 60 ? 0 : cargaHorariaTotal / 3600; 
            
                let totalHorasObrigatorias = obrigatorias
                    .filter(result => result.type === "WORKING_DAY")
                    .reduce((acc, result) => acc + (result.requiredSeconds || 0), 0) / 3600;

                const worklogForCollaborator = realizadas.filter(worklog => worklog.author.accountId === colaborador.accountId) || [];
            
                let duracao = worklogForCollaborator.reduce((acc, worklog) => acc + (worklog.timeSpentSeconds || 0), 0) / 3600;
                let horasFaturaveis = worklogForCollaborator.reduce((acc, worklog) => acc + (worklog.billableSeconds || 0), 0) / 3600;

                let horasLivres = totalHorasObrigatorias - horasPlanejadas;
            
                return {
                    ...colaborador,
                    horasPorDia,
                    totalPlannedHoursInScope: horasPlanejadas,
                    totalHorasObrigatorias,
                    duracao,
                    horasFaturaveis,
                    horasLivres
                };
            });
            setColaboradoresComHoras(resultadosFetch);
        }
    }, [fetchDadosColaboradores]);

    useEffect(() => {
        const filtered = colaboradoresComHoras.filter((item) => {
            const matchesDepartment = selectedDepartment
                ? item.departamento === (selectedDepartment === 'Delivery' ? 5 : selectedDepartment === 'ENGESOL' ? 16 : 20)
                : true;
            const matchesRegime = selectedRegime
                ? item.regime === (selectedRegime === 'CLT' ? 1 : 3)
                : true;
            return matchesDepartment && matchesRegime;
        });
        setFilteredColaboradores(filtered);
    }, [selectedDepartment, selectedRegime, colaboradoresComHoras]);

    useEffect(() => {
        setOrdenacaoColaborador(filteredColaboradores.sort((a, b) => a.nome.localeCompare(b.nome)));
    },[filteredColaboradores])

    const exportDataInExcel = async () => {
        const header = [
            [
                'Nome do Projeto', 'Colaborador', 'Status', 'Departamento', 'Regime', 'Perfil', 'Carga Horária Diária', 'Total Horas Obrigatórias', 'Total Horas Planejadas', 'Horas Livres Planejadas', 'Ocupação Planejada(%)', 'Total de Horas Realizadas', 'Ocupação Realizada (%)', 'Total de Horas Faturáveis', 'Ocupação Faturável (%)', 'Variação Faturável (%)'
            ]
        ];

        const formatedData = filteredColaboradores.map(item => {
            const cargaHorariaDiaria = parseFloat(item.horasPorDia.toFixed())

            const desvio = item.totalPlannedHoursInScope === 0
                ? -100
                : ((item.totalPlannedHoursInScope - item.horasFaturaveis) / item.totalPlannedHoursInScope) * 100;

            return {
                nomeProjeto: data.projetosSelecionados[0].value,
                colaborador: `${item.nome} ${item.sobrenome}`,
                status: item.demissao ? "Inativo" : "Ativo",
                departamento: item.departamento === 5 ? "Delivery" : item.departamento === 16 ? "ENGESOL" : "Overhead",
                regime: item.regime === 1 ? "CLT" : item.regime === 3 && "Estagiário",
                perfil: funcoes.find(funcao => funcao.cod === item.funcao)?.nome,
                cargaHorariaDiaria,
                totalHorasObrigatorias: `${item.totalHorasObrigatorias.toFixed()}h`,
                totalHorasPlanejadas: `${item.totalPlannedHoursInScope.toFixed()}h`,
                horasLivres: `${Math.floor(item.horasLivres)}h`,
                ocupacaoPlanejada: item.demissao ? "-- " : `${((item.totalPlannedHoursInScope * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                totalHorasRealizadas: `${item.duracao.toFixed()}h`,
                ocupacaoRealizada: `${((item.duracao * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                totalHorasFaturaveis: `${item.horasFaturaveis.toFixed()}h`,
                ocupacaoFaturavel: `${((item.horasFaturaveis * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                variacaoFaturavel: parseFloat(desvio.toFixed(2))
            };
        });

        UtilService.exportXls(formatedData, `Relatório Capacidade: ${data.periodoInicial} - ${data.periodoFinal}`, header);
    };

    const [sortDirection, setSortDirection] = useState({});

    const handleOrdenar = (key) => {

        let direction = sortDirection[key] || "cima";
        direction = direction === "cima" ? "baixo" : "cima";
        setSortDirection({ ...sortDirection, [key]: direction });

        const sortedColaboradores = [...filteredColaboradores].sort((a, b) => {
            if (key === "colaborador") {
                return direction === "cima" ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome);
            } else if (key === "departamento") {
                return direction === "cima" ? a.departamento - b.departamento : b.departamento - a.departamento;
            } else if (key === "regime") {
                return direction === "cima" ? a.regime - b.regime : b.regime - a.regime;
            } else if (key === "perfil") {
                return direction === "cima" ? a.funcao - b.funcao : b.funcao - a.funcao;
            } else if (key === "cargaHoraria") {
                return direction === "cima" ? a.horasPorDia - b.horasPorDia : b.horasPorDia - a.horasPorDia;
            } else if (key === "ocupacaoPlanejada") {
                const ocupPlanA = ((a.totalPlannedHoursInScope * 100) / a.totalHorasObrigatorias);
                const ocupPlanB = ((b.totalPlannedHoursInScope * 100) / b.totalHorasObrigatorias);
                return direction === "cima" ? ocupPlanA - ocupPlanB : ocupPlanB - ocupPlanA;
            } else if (key === "horasObrigatorias") {
                return direction === "cima" ? a.totalHorasObrigatorias - b.totalHorasObrigatorias : b.totalHorasObrigatorias - a.totalHorasObrigatorias;
            } else if (key === "horasLivres") {
                return direction === "cima" ? a.horasLivres - b.horasLivres : b.horasLivres - a.horasLivres;
            } else if (key === "horasPlanejadas") {
                return direction === "cima" ? a.totalPlannedHoursInScope - b.totalPlannedHoursInScope : b.totalPlannedHoursInScope - a.totalPlannedHoursInScope;
            } else if (key === "horasRealizadas") {
                return direction === "cima" ? a.duracao - b.duracao : b.duracao - a.duracao;
            } else if (key === "ocupacaoRealizada") {
                const ocupRealA = ((a.duracao * 100) / a.totalHorasObrigatorias);
                const ocupRealB = ((b.duracao * 100) / b.totalHorasObrigatorias);
                return direction === "cima" ? ocupRealA - ocupRealB : ocupRealB - ocupRealA;
            } else if (key === "horasFaturaveis") {
                return direction === "cima" ? a.horasFaturaveis - b.horasFaturaveis : b.horasFaturaveis - a.horasFaturaveis;
            } else if (key === "ocupacaoFaturavel") {
                const ocupFatuA = ((a.horasFaturaveis * 100) / a.totalHorasObrigatorias);
                const ocupFatuB = ((b.horasFaturaveis * 100) / b.totalHorasObrigatorias);
                return direction === "cima" ? ocupFatuA - ocupFatuB : ocupFatuB - ocupFatuA;
            } else if (key === "variacaoFaturavel") {
                const desvioA = a.totalPlannedHoursInScope === 0
                    ? -100
                    : (((a.totalPlannedHoursInScope) - a.horasFaturaveis) / (a.totalPlannedHoursInScope)) * 100;
    
                const desvioB = b.totalPlannedHoursInScope === 0
                    ? -100
                    : (((b.totalPlannedHoursInScope) - b.horasFaturaveis) / (b.totalPlannedHoursInScope)) * 100;
    
                return direction === "cima" ? desvioA - desvioB : desvioB - desvioA;
            }
        });
    
        setOrdenacaoColaborador(sortedColaboradores);
    };

    const SortIcon = ({ column, sortDirection }) => {
        if (!sortDirection[column]) {
            return <FaSort className="ml-1" style={{ opacity: 0.3 }} />;
        }
        return sortDirection[column] === "cima" ? 
            <FaSortUp className="ml-1" /> : 
            <FaSortDown className="ml-1" />;
    };

    const totalPlannedHoursInScope = filteredColaboradores.reduce((total, item) => {
        return total + item.totalPlannedHoursInScope;
    }, 0);

    const totalHorasObrigatorias = filteredColaboradores.reduce((total, item) => {
        return total + item.totalHorasObrigatorias;
    }, 0)

    const totalDuracao = filteredColaboradores.reduce((total, item) => {
        return total + item.duracao;
    }, 0)

    const totalHorasFaturaveis =  filteredColaboradores.reduce((total, item) => {
        return total + item.horasFaturaveis;
    }, 0)

    const totalHorasLivres = filteredColaboradores.reduce((total, item) => {
        return total + item.horasLivres;
    }, 0)

    const riscoDemissao = {
        textDecoration: "line-through"
    }

    const handleCancel = () => {
        history.push('../relatorios-delivery/');
    };

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: handleCancel
        }
    ];

    const fixarTitulo = {
        "position": "sticky", 
        "top":0, 
        "zIndex": 9998, 
        "backgroundColor" : "#f5f5f5",
    }

    const fixarThead = { 
        position: "sticky", 
        top: 0, 
        zIndex: 1000, 
        backgroundColor: "#f5f5f5", 
        "boxShadow" :" 0 4px 4px -4px rgba(0, 0, 0, 0.3)" 
    }

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    return (
        <>
        <div style={fixarTitulo}>
            <PageContainer title={"Relatório Capacidade por Projeto"} actions={actions}>
                <h5>Período Inicial: {data.periodoInicial}</h5>
                <h5 className="mb-4">Período Final: {data.periodoFinal}</h5>
                <h5 className="mb-4">Projetos: {data.projetosSelecionados.map(proj => proj.value) + "; "}</h5>
                {data.cliente && <h5 className="mb-4">Cliente: {data.cliente}</h5>}
                
                <div className="d-flex mb-3" style={{position: "sticky"}}>
                <Button onClick={() => exportDataInExcel()} className='mr-3'>Exportar</Button>
                <Dropdown className="mr-3">
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Departamento: <span style={{ fontWeight: 'bold' }}>{selectedDepartment ? selectedDepartment : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Delivery')}>
                            Delivery
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('ENGESOL')}>
                            ENGESOL
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Overhead')}>
                            Overhead
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Regime: <span style={{ fontWeight: 'bold' }}>{selectedRegime  ? selectedRegime : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedRegime('CLT')}>
                            CLT
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('Estagiário')}>
                            Estagiário
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </PageContainer>
            </div>
                {loading ? <Loading message={"Carregando..."} /> : 
                (filteredColaboradores.length > 0 && !loading ? (
                    <div id="tabela-personalizada-relatorio-capacidade" style={{maxHeight: "600px", overflowY: "auto"}}>
                    <Table striped bordered hover responsive="xl">
                    <thead style={fixarThead}>
                        <tr>
                        <th className="fixed-column no-wrap" onClick={() => handleOrdenar("colaborador")}>Colaborador <SortIcon column="colaborador" sortDirection={sortDirection} /></th>
                        <th className="no-wrap" onClick={() => handleOrdenar("departamento")}>Departamento <SortIcon column="departamento" sortDirection={sortDirection} /></th>
                        <th className="no-wrap" onClick={() => handleOrdenar("regime")}>Regime do contrato <SortIcon column="regime" sortDirection={sortDirection} /></th>
                        <th className="no-wrap" onClick={() => handleOrdenar("perfil")}>Perfil <SortIcon column="perfil" sortDirection={sortDirection} /></th>
                        <th className="no-wrap" onClick={() => handleOrdenar("cargaHoraria")}>Cargas horárias <br/> diárias <SortIcon column="cargaHoraria" sortDirection={sortDirection} /></th>
                        <th className="no-wrap" onClick={() => handleOrdenar("horasObrigatorias")}>Total horas <br/> Obrigatórias <SortIcon column="horasObrigatorias" sortDirection={sortDirection} /></th>
                        <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar("horasPlanejadas")}>Total horas <br/> Planejadas <SortIcon column="horasPlanejadas" sortDirection={sortDirection} /></th>
                        <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar("horasLivres")}>Horas Livres <br/> Planejadas <SortIcon column="horasLivres" sortDirection={sortDirection} /></th>
                        <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar("ocupacaoPlanejada")}>Ocupação <br/> Planejada (%) <SortIcon column="ocupacaoPlanejada" sortDirection={sortDirection} /></th>
                        <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar("horasRealizadas")}>Total horas <br/> Realizadas <SortIcon column="horasRealizadas" sortDirection={sortDirection} /></th>
                        <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar("ocupacaoRealizada")}>Ocupação <br/> Realizada (%) <SortIcon column="ocupacaoRealizada" sortDirection={sortDirection} /></th>
                        <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar("horasFaturaveis")}>Total horas <br/> Faturáveis <SortIcon column="horasFaturaveis" sortDirection={sortDirection} /></th>
                        <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar("ocupacaoFaturavel")}>Ocupação <br/> Faturável (%) <SortIcon column="ocupacaoFaturavel" sortDirection={sortDirection} /></th>
                        <th className="no-wrap cinza-claro-variacao" onClick={() => handleOrdenar("variacaoFaturavel")}>Variação <br/> Faturável (%)
                            <span 
                            onMouseEnter={()=> handleMouseEnter('variacao_faturavel')}
                            onMouseLeave={handleMouseLeave}
                            className="ml-1 info-icon"
                            style={{ position: 'relative', cursor: 'pointer'}}
                            >
                                <FiInfo />
                                {visiblePopup === 'variacao_faturavel' && (
                                    <div className="hover-popup" style={popupStyle}> 
                                        Diferença entre total de horas planejadas e o total de horas faturáveis.
                                    </div>
                                )}
                            </span>
                            <SortIcon column="variacaoFaturavel" sortDirection={sortDirection} />
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordenacaoColaborador
                        .map((item, index) => {
                            return (
                            <tr key={index}>
                            <td className="fixed-column no-wrap" style={item.demissao ? riscoDemissao : {}}>{item.nome} {item.sobrenome} </td>
                            <td className="no-wrap">{item.departamento === 5 ? "Delivery" : item.departamento === 16 ? "ENGESOL" : "Overhead"}</td>
                            <td className="no-wrap">{item.regime === 1 ? "CLT" : item.regime === 3 && "Estagiário"}</td>
                            <td className="no-wrap">{funcoes.find(funcao => funcao.cod === item.funcao)?.nome}</td>
                            <td className="no-wrap">{item.horasPorDia.toFixed()}h</td>
                            <td className="no-wrap">{item.totalHorasObrigatorias.toFixed()}h</td>
                            <td className="no-wrap verde-claro-planejado">{item.totalPlannedHoursInScope.toFixed()}h</td>
                            <td className="no-wrap verde-claro-planejado">{ Math.floor(item.horasLivres)}h</td>
                            <td className="no-wrap verde-claro-planejado">{item.demissao ? "-- " : ((item.totalPlannedHoursInScope * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap azul-claro-realizado">{item.duracao.toFixed()}h</td>
                            <td className="no-wrap azul-claro-realizado">{((item.duracao * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap azul-claro-realizado">{item.horasFaturaveis.toFixed()}h</td>
                            <td className="no-wrap azul-claro-realizado">{((item.horasFaturaveis * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap cinza-claro-variacao">
                                {(() => {
                                    const desvio = item.totalPlannedHoursInScope === 0
                                    ? -100
                                    : ((item.totalPlannedHoursInScope - item.horasFaturaveis) / item.totalPlannedHoursInScope) * 100;

                                    return `${desvio.toFixed()}%`;
                                })()}
                            </td>
                            </tr>
                            )})}
                        <tr>
                        <td className="fixed-column no-wrap"><strong>Total de colaboradores: {filteredColaboradores.length}</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="no-wrap"><strong>{totalHorasObrigatorias.toFixed()}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{totalPlannedHoursInScope.toFixed()}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{Math.floor(totalHorasLivres)}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{isNaN(((totalPlannedHoursInScope * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalPlannedHoursInScope * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{totalDuracao.toFixed()}h</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalDuracao * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalDuracao * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{totalHorasFaturaveis.toFixed()}h</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalHorasFaturaveis * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalHorasFaturaveis * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap cinza-claro-variacao">
                            <strong>
                                {(() => {
                                    const desvio = totalPlannedHoursInScope == 0
                                    ? -100
                                    : ((totalPlannedHoursInScope - totalHorasFaturaveis) / totalPlannedHoursInScope) * 100;

                                    return `${desvio.toFixed()}%`;
                                })()}
                            </strong>
                        </td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
                ) : <div>Nenhum colaborador encontrado.</div>)
                }
        </>
    );
}

export default RelatorioCapacidadeProjetoView;
